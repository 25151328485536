<template>
    <div :class="[flag? '': 'content-box']">
        <div :class="[flag? '': 'container']">
            <div class="content">
            <div style="font-size:16px;margin:15px 0">
                 {{data.name}} 工时记录
            </div>
             <div class="listBox" v-for="(item,index) in laborList" :key="index">
                <div class="list_row">
                    <div class="flex">
                        <div><label for="">工作内容：</label>{{item.sname}}</div>
                    </div>
                    <div class="flex">
                        <div><label for="">日期：</label>{{item.date}}</div>
                        <div><label for="">时长：</label>{{item.time}}</div>
                    </div>
                    <div class="flex">
                        <div><label for="">工人：</label>{{item.temporary.join(',')}}</div>
                    </div>
                </div>
                 <div class="btn_con">
                    <a-button type="primary" size="small" ghost @click="showEditLabor(item)">编辑</a-button>
                    <a-popconfirm title="确定要删除吗?"  @confirm="() => delLabor(item.id)">
                        <a-button type="danger" size="small" ghost>删除</a-button>
                    </a-popconfirm>
                </div>
            </div>
            <!-- <table width="100%" style="line-height:45px">
                     <tr>
                        <th>出勤日期</th>
                        <th>工人姓名</th>
                        <th>出勤时长</th>
                        <th>工作内容</th>
                        <th>操作</th>
                    </tr>
                    <tr >
                        <td>{{item.date}}</td>
                        <td>{{item.temporary.join(',')}}</td>
                        <td>{{item.time}}</td>
                        <td>{{item.sname}}</td>
                        <td class="action1"><span @click="showEditLabor(item)">编辑</span><span @click="delLabor(item.id)">删除</span></td>
                    </tr>
                </table> -->
            <a-modal v-model="showModal" :centered="true" title="添加工时" @ok="editLabor()">
                <div class="addBox" style="padding-bottom:50px">
                    <div class="add_title" >请选择工人</div>
                    <a-select v-model="onTemp" mode="multiple" class="ant-select-lg" style="width: 350px;">
                            <a-select-option :value="item.id" v-for="(item,index) in tempList" :key="index">{{item.name}}</a-select-option>
                    </a-select>
                    <div class="add_title">请输入工时</div>
                    <a-input class="add_inp ant-input-lg" type = "number" style="width: 350px;" v-model="onLabor.time" step = "0.1"/>
                    <div class="add_title">请选择出勤日期</div>
                    <a-date-picker v-model="onLabor.date" :inputReadOnly="true" @change="onPickTime" style="width: 350px;" size="large" />
                    <div class="add_title">请选择工作内容</div>
                    <a-select v-model="onLabor.skillid" class="ant-select-lg" style="width: 350px;">
                        <a-select-option :value="item.id" v-for="(item,index) in skillid" :key="index">{{item.name}}</a-select-option>
                    </a-select>
                </div>
            </a-modal>
            </div>
        </div>
        </div>
</template>
<script>
// import $ from "jquery";
import {isMobile,ajaxUrl,requestXml,getDateTime} from '../../../assets/js/request';
export default {
    props: {
        addFlag: {
            type: Boolean,
            default: false
        },
        taskId: {
            type: String
        }
    },
    data() {
        return {
            flag: true,
            id: "", 
            ajaxUrl:ajaxUrl,
            staff:'', //员工列表
            skillid:'', //技能列表
            data:{
                "name": "",
                "orderid": '',
                "createtime": "",
                "endtime": "",
                "corpid": '',
                "project_type": "",
                "address": "",
                "responsibility": "",
                "introduce":'',
                "commission":'',
                "actual_day": "",
                "actual_money": "",
                "payment_money": "",
                "payment_goods": "",
                "status": 1,
                "remarks": "",
            },
            createtime:'', //开始时间
            endtime:'',    //结束时间
            selSkillid:'',  //技能选项
            clientList:'', //客户列表
            orderList:'', //合同列表
            // 工时相关
            laborList:'', //
            showModal:false,  //修改工时弹框
            tempList:[], //工人列表
            onLabor:'',  //编辑状态工时
            onTemp:[],
       };
    },
    mounted: function(){
        this.id = this.$route.query.id;
        this.getStaff();
        this.getClient();
        this.getOrder();
        this.getSkillid();
         if(this.id){
            this.getDetail();
            this.getLabor();
            this.getTemp();
        }
        if(isMobile()){
            this.flag = true;
        }else{
            this.flag = false;
        }
    },

    methods: {
         submit(){
            let url='/jieb/Temporary/interviewadd';
               var time=new Date().getTime();
            let data={
                'temporaryid':this.id,
                'record':this.record,
                'time':Math.round(time/1000)
            }
             requestXml(url,"POST",(status,res) => {
                console.log(status,res);
                if(status!='error'){
                    this.$message.success('成功');
                    this.getRecord();
                    this.record='';
                }
                if(res){
                    this.$message.info(res.msg);
                }
            },data)
        },
         onPickTime(date, dateString){
            console.log(date);
           this.onLabor.date=dateString;
        },
           // 获取工人列表
        getTemp(){
             requestXml("/jieb/Temporary/tempbaseshow","POST",(res) => {
                this.tempList = res.list;
            },{"page":{"curpage":1,"pagesize":9999}})
        },
        showEditLabor(item){
            item.skillid=Number(item.skillid);
            let onTemp=item.temporaryid.split(',');
            for(let i=0;i<onTemp.length;i++){
                onTemp[i]=Number(onTemp[i]);
            }
            this.onTemp=onTemp;
            this.onLabor= Object.assign({}, item);
            this.showModal=true;
        },
        delLabor(id){
            requestXml("/jieb/Project/attendancedel","POST",() => {
                   this.getLabor();
            },{id:id})
        },
        editLabor(){

            requestXml("/jieb/Project/attendanceedit","POST",() => {
                this.getLabor();
                this.showModal=false;
            },{id:this.onLabor.id,projectid:this.id,temporaryid:this.onTemp.join(','),skillid:this.onLabor.skillid,time:this.onLabor.time,date:this.onLabor.date});
        },
        getLabor(){
              requestXml("/jieb/Project/attendanceshow","POST",(res) => {
                console.log(res);
               this.laborList=res.list;
            },{projectid:this.id});
        },
        // 选择开始时间
        onPickCTime(date, dateString){
            console.log(date);
            var time=new Date(dateString).getTime();
            this.data.createtime= Math.round(time/1000); 
        },
         onPickETime(date, dateString){
            console.log(date);
            var time=new Date(dateString).getTime();
            this.data.endtime= Math.round(time/1000); 
        },
        // 获取合同数据
        getOrder(){
            requestXml("/Scrm/Order/getMyList","POST",(res) => {
                console.log(res);
             this.orderList=res.list;
            },{"page":{"curpage":1,"pagesize":999999}})
        },
        // 获取客户列表
        getClient(){
            requestXml("/scrm/Corp/getUnBindCorp","POST",(res) => {
                this.clientList=res;
                console.log(res);
            })
        },
        // 获取技能分类
        getSkillid(){
             requestXml("/jieb/Temporary/skillshow","POST",(res) => {
                this.skillid=res.list;
            },{"page":{"curpage":1,"pagesize":999999}})
        },
         getStaff(){
            requestXml("/scrm/Staff/getMinList","POST",(res) => {
                this.staff=res;
            })
        },
        // 获取详情
        getDetail() {
            requestXml("/jieb/Project/projectedit","GET",(res) => {
               console.log(res);
               if(res.orderid==0){res.orderid=''}
                this.createtime=getDateTime(res.createtime);
               this.endtime=getDateTime(res.endtime);
                this.data = Object.assign({}, res);
            },{id: this.id})
        },
    }
};
</script>
<style>
     .content{
        min-height: 100vh;
    }
    .listBox{
        margin: 0 15px;
        margin-bottom: 10px;
        padding: 15px;
        border: 1px solid #ededed;
        border-radius: 5px;
    }
   .minInput{
        width: 200px;
        margin-left: 15px;
        margin-right: 15px;
    }
    .page_select {
        position: fixed;
        bottom: 0px;
        display: flex;
        justify-content: center;
        width: 100%;
        background: #fff;
    }
    .search_wap{
        text-align: left;
        margin-bottom: 15px;
        height: 40px;
        line-height: 40px;
        background-color: #fff;
    }
     .fixedBtn{
        position: fixed;
        right: 15px;
        bottom: 15px;
        z-index: 999;
        height: 43px;
        border-radius: 50px;
    }
    .search_wap .ant-input-search{
        width: 90vw;
        margin: 0 5vw;
    }.a-inline-block{
        display: flex;
        align-items: center;
        height: 40px;

    }
    .btn_con{
        text-align: left;
    }
    .btn_con button{
        color: #36A3FF;
        border-color: #36A3FF;
        margin: 1rem 1rem 0 0;
    }  

</style>
